import EqualizerIcon from "@mui/icons-material/Equalizer";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Popover, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginContext } from "../../context";
import { KeyCode, addSongToPlaylist, isSelected, truncateText } from "../../utils";
import PlanToast from "../PlanToast/PlanToast";
import { SongsTableStyle } from "./SongsTable.style";
import { usePlaylistContext } from "../../context/PlaylistContext";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { stat } from "fs";
import DraggableRow from "./DragableRow";
import axios from "axios";
import { useParams } from "react-router";
import { debounce } from "@mui/material";

type Props = {
  state: any;
  playlistState: any;
  menu: any;
  HideAddNote?: any;
  HideDates?: any;
  hideMenu?: any;
  spotifyTrue?: any;
  setIndexes?: any;
  fromBrowse?: any;
  numberRef?: any;
  titleRef?: any;
  popularityRef?: any;
  dateRef?: any;
  spotifyRef?: any;
  actionRef?: any;
  bulkAction?: any;
  selectSongFunction?: any;
  currentPage?: any;
  selectedSongs?: any;
  setBulkAction?: any;
  selectMultpleSongFunction?: any;
  setState?: any;
  refetchPlaylist?: any;
  tableRef?: any;
  fetchData?: any;
  selectSingleSong?: any;
  selectedIndex?: any;
  setSelectedIndex?: any;
  PlaylistRefreshRecordLoader?: any;
};

function SongsTable({
  state,
  setState,
  playlistState,
  menu,

  HideDates,
  hideMenu,
  spotifyTrue,

  fromBrowse,
  numberRef,
  titleRef,
  popularityRef,
  dateRef,
  spotifyRef,
  actionRef,
  bulkAction,
  selectSongFunction,
  currentPage,
  selectedSongs,
  setBulkAction,
  selectMultpleSongFunction,
  refetchPlaylist,
  tableRef,
  fetchData,
  selectSingleSong,
  selectedIndex,
  setSelectedIndex,
  PlaylistRefreshRecordLoader
}: Props) {
  const { state: loginState } = useLoginContext();
  const dispatch = useDispatch();
  const { state: playliststate, dispatch: playlistDispatch } = usePlaylistContext();
  let SearchId = playlistState?.selectedPlaylistId || ""

  const handleAddSong = useCallback(
    (selectedSong) => {
      // @ts-ignore
      const url = new URL(window.location);
      let playlistId = url.searchParams.get("playlist");
      dispatch({
        type: "SET_SEARCHED_SONG_DATA",
        payload: selectedSong.songUri,
      });

      addSongToPlaylist(
        Array.isArray(playlistId) ? playlistId : [playlistId],
        selectedSong.songUri,
        loginState.token,
        loginState.refreshToken,
        loginState.userId,
        1,
        "",
        "",
        "past",
        ""
      )
        .then((response) => {
          message.info("Song added to playlist");
        })
        .catch((e) => {
          message.error("Error while adding song");
        });
    },
    [dispatch, loginState.refreshToken, loginState.token, loginState.userId]
  );
  const [ctrlHold, setCtrlHold] = useState(false)
  const [shiftHold, setShiftHold] = useState(false)
  useEffect(() => {
    window.addEventListener('keydown', (ev) => {
      // console.log(ev.keyCode)
      // KeyCode()
      // console.log(ev.keyCode)
      if (ev.keyCode === KeyCode('ctrl')) {
        setCtrlHold(true)
      }
      if (ev.keyCode === KeyCode('shift')) {
        setShiftHold(true)
      }
      // if (ev.keyCode === 46) {
      //   showModalDeleteSelected()
      // }
    })

    window.addEventListener('keyup', (ev) => {
      if (ev.keyCode === KeyCode('ctrl')) {
        setCtrlHold(false)
      }
      if (ev.keyCode === KeyCode('shift')) {
        setShiftHold(false)
      }
      // if (ev.keyCode === 46) {
      //   showModalDeleteSelected()
      // }
    })
  }, [])

  const selectSongFn = (a, i) => {
    selectSongFunction({
      uri: a?.rowNumber?.e?.track.uri,
      positions: [
        a.lockPosition
          ? Number(a.lockPositionNumber)
          : i + (currentPage - 1) * 50,
      ],
    }, a._id, i)
  }

  const selectSingleSongFn = (a, i) => {
    selectSingleSong({
      uri: a?.rowNumber?.e?.track.uri,
      positions: [
        a.lockPosition
          ? Number(a.lockPositionNumber)
          : i + (currentPage - 1) * 50,
      ],
    }, a._id, i)
  }

  const rangeSongSelect = (a, toSelect) => {
    let idArr = []
    let arr = []
    state?.map((b, eachSong) => {
      if ((eachSong >= selectedIndex && toSelect >= eachSong) || eachSong <= selectedIndex && toSelect <= eachSong) {
        if (b._id) {
          idArr.push(b._id)
        }
        arr.push({
          uri: b?.rowNumber?.e?.track.uri,
          positions: [
            b.lockPosition
              ? Number(b.lockPositionNumber)
              : eachSong + (currentPage - 1) * 50,
          ]
        })
      }
    })
    selectMultpleSongFunction(arr, idArr)
  }
  // useEffect(() => {
  //   console.log(state, 'asdasdasd')
  // }, [state])
  const [updateDrag, setUpdateDrag] = useState(false)
  const [dropIndex, setDropIndex] = useState<number | false>(false)
  const [dragItemIndex, setDragItemIndex] = useState<number | false>(false)
  const { playlistId } = useParams();
  const [updatedDragState, setUpdateDragState] = useState([])
  const [hoverID, setHoverID] = useState<number | string>('')
  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRow = state[dragIndex];
    // console.log(dragRow, hoverIndex, 'hoverIndexhoverIndex')
    // if (!(Number(dropIndex) >= 0)) {
    // console.log(hoverIndex, 'hoverIndex')
    setDropIndex(hoverIndex)
    // }
    if (dragItemIndex === false) {
      // console.log(dragIndex, 'dragItemIndex')
      setDragItemIndex(dragIndex)
    }
    const updatedRows = [...state];
    updatedRows.splice(dragIndex, 1);
    updatedRows.splice(hoverIndex, 0, dragRow);
    // console.log(updatedRows, 'movedRowmovedRowmovedRow 1')
    setState(updatedRows);

  };
  const [lastDropIndex, setLastDropIndex] = useState<number | false>(false)
  const [lastDragItemIndex, setLastDragItemIndex] = useState<number | false>(false)

  const endDrag = async (dragIndex: number, item: { id: number, index: number }) => {
    // console.log(dropIndex, dragItemIndex, 'asdasdasdasdas')
    setUpdateDrag(!updateDrag)
    // setLastDropIndex(dropIndex)
    setLastDragItemIndex(dragItemIndex)
    // setDropIndex(false)
    setDragItemIndex(false)

  };


  useEffect(() => {
    // console.log(state, dropIndex, dragItemIndex, 'asdasdasdasdas')

    axios(
      `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
      {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + loginState.token,
        },
        data: JSON.stringify({
          range_start: dragItemIndex,
          insert_before: Number(dragItemIndex) < Number(dropIndex) ? Number(dropIndex) + 1 : Number(dropIndex),
          range_length: 1,
        }),
      }
    )
      .then((genreResponse) => {
        // setLastDropIndex(false)
        setLastDragItemIndex(false)
        fetchData();
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
        }
      });
  }, [updateDrag])

  const PcTable = () => {
    return (
      <div className="tablePlayListDiv" >
        {PlaylistRefreshRecordLoader ?

          <div className="loadingDivPage">
            <div className="loadingDiv">
              <p className="refreshText playlistRefrestText">
                Loading playlist
              </p>
              <div className="loadingCircle playlistloadingCircle"></div>
            </div>
          </div>
          :
          <>
            {spotifyTrue ? (
              <table className="tablePlayList" id="table" >
                <thead>
                  <tr>
                    {/* {selectedSongs.length ? */}
                    {/* <th>
                  Select
                </th> */}
                    {/* : null} */}
                    <th
                      ref={numberRef}
                      className="seriesNumber"
                    >
                      #
                    </th>
                    <th
                      ref={titleRef}
                    >
                      Title / Artist
                    </th>
                    {/* {state[0]?.popularity >= 0 ? */}
                    <th ref={popularityRef} style={{ textAlign: "center" }}>
                      Popularity
                    </th>
                    {/* : null} */}
                    {HideDates ? null : (
                      <th ref={dateRef}>Start date / End date</th>
                    )}
                    <th ref={spotifyRef}></th>
                    <th
                      ref={actionRef}
                      style={{ textAlign: "right", padding: "0px 20px" }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {state
                    ? state.map((a, i) => {
                      return (
                        // <DraggableRow hoverID={hoverID === i ? hoverID : false} tableRef={tableRef} endDrag={endDrag} className={(isSelected(a, selectedSongs, currentPage, i) ? 'selected' : null) + ' rowTable'} onClick={() => {
                        //   if (!bulkAction) {
                        //     setBulkAction(true)
                        //   }
                        //   if (ctrlHold) {
                        //     selectSongFn(a, i)
                        //   }
                        //   if (shiftHold) {
                        //     rangeSongSelect(a, i)
                        //   }
                        //   setSelectedIndex(i)
                        // }} key={a.id} id={a.id} index={i} moveRow={moveRow}>
                        <tr className={(isSelected(a, selectedSongs, currentPage, i) ? 'selected' : null) + ' rowTable'} onClick={() => {
                          if (!bulkAction) {
                            setBulkAction(true)
                          }
                          if (ctrlHold) {
                            selectSongFn(a, i)
                            // setSelectedIndex(i)
                          }
                          if (shiftHold) {
                            rangeSongSelect(a, i)
                            // setSelectedIndex(i)
                          }
                          if (!ctrlHold && !shiftHold) {
                            selectSingleSongFn(a, i)
                          }
                        }}>


                          {/* {selectedSongs.length ? */}
                          {/* <td>
                        <div className="checkbox" onClick={(e) => {
                          e?.stopPropagation()
                          console.log(a, 'asdasdasd')
                          if (shiftHold) {
                            rangeSongSelect(a, i)
                            // setSelectedIndex(i)
                          } else {
                            selectSongFn(a, i)
                          }
                        }}>

                          <>
                            {isSelected(a, selectedSongs, currentPage, i) ?
                              <CheckBoxIcon />
                              :
                              <CheckBoxOutlineBlankIcon />
                            }
                          </>
                        </div>
                      </td> */}
                          {/* : null}  */}
                          <td>
                            {fromBrowse ? (
                              i + 1
                            ) : (
                              <>
                                {playlistState?.previewSong?.id === a?.id ? (
                                  <div style={{ padding: "6px 0px 0px 0px " }} onClick={() => {

                                    playlistDispatch({
                                      type: "SET_PREVIEW_SONG",
                                      payload: {
                                        url: "",
                                        id: "",
                                        name: "",
                                        artist: "",
                                        image: "",
                                        external: "",
                                      },
                                    });

                                  }}>
                                    <EqualizerIcon
                                      style={{
                                        marginLeft: "15px",
                                      }}
                                      className="icons45"
                                    />
                                  </div>
                                ) : a?.key ? (
                                  a?.key
                                ) : (
                                  <div
                                    className="playButtonPlayList"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      if (a.previewUrl) {
                                        if (a.clickHandleFn) {
                                          a.clickHandleFn();
                                        } else {
                                          a?.clickHandle(
                                            a?.previewUrl,
                                            a?.id,
                                            a?.image,
                                            a?.title,
                                            a?.artist,
                                            a?.externalUrl
                                          );
                                        }
                                      } else {
                                        // window.open(a.externalUrl, '_blank')
                                      }
                                    }}
                                  >
                                    {a.previewUrl ? (
                                      <PlayArrowIcon
                                        style={{
                                          color: "white",
                                          // marginLeft: "10px",
                                        }}
                                        className="icons1"
                                      />
                                    ) : (
                                      <img
                                        alt="spotifylogo"
                                        src={'./../static/images/spotifyLogo.png'}
                                        width={28}
                                        height={28}
                                        className="icons1"
                                        onClick={() => {
                                          window.open(a.externalUrl, "_blank");
                                        }}
                                      />
                                    )}
                                    <span
                                      className="icons2"
                                      style={{
                                        width: "",
                                        // marginLeft: "-10px",
                                      }}
                                    >
                                      {/* {a?.rowNumber?.indexes} */}
                                      {i + 1}
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </td>
                          <td className="handle">
                            {typeof a?.image === "string" ? (
                              <div className="imageName">
                                <span className="imgSpan">
                                  <span id="hancker">
                                    <img
                                      className="table-list-image"
                                      alt="thumbnail"
                                      src={a?.image}
                                      onClick={() => {
                                        a?.clickHandle(
                                          a?.previewUrl,
                                          a?.id,
                                          a?.image,
                                          a?.title,
                                          a?.artist,
                                          a?.externalUrl
                                        );
                                      }}
                                      width="40"
                                      height="40"
                                    />
                                  </span>
                                </span>
                                <div className="namePlaylist">
                                  <>
                                    {typeof a.title === "string" ? (
                                      <Popover
                                        content={<p>{a?.title}</p>}
                                        placement="topLeft"
                                      >
                                        <p
                                          id="hancker"
                                          onClick={() => {
                                            a?.clickHandle(
                                              a?.previewUrl,
                                              a?.id,
                                              a?.image,
                                              a?.title,
                                              a?.artist,
                                              a?.externalUrl
                                            );
                                          }}
                                        >
                                          {truncateText(a?.title, 23)}
                                        </p>
                                      </Popover>
                                    ) : (
                                      <p
                                        id="hancker"
                                        onClick={() => {
                                          a?.clickHandle(
                                            a?.previewUrl,
                                            a?.id,
                                            a?.image,
                                            a?.title,
                                            a?.artist,
                                            a?.externalUrl
                                          );
                                        }}
                                      >
                                        {truncateText(a?.title, 23)}
                                      </p>
                                    )}
                                    <p
                                      id="hancker"
                                      onClick={() => {
                                        a?.clickHandle(
                                          a?.previewUrl,
                                          a?.id,
                                          a?.image,
                                          a?.title,
                                          a?.artist,
                                          a?.externalUrl
                                        );
                                      }}
                                    >
                                      {" "}
                                      {truncateText(a?.artist, 18)}
                                    </p>
                                  </>
                                  {/* <h3>Cold Heart - PNAU Remix</h3>
            <p>My Playlist</p> */}
                                </div>
                              </div>
                            ) : (
                              <div className="imageName">
                                <span className="imgSpan">{a?.image}</span>
                                <div className="namePlaylist">{a?.name}</div>
                              </div>
                            )}
                          </td>
                          {/* {state[0].popularity ? */}
                          <td
                            style={{ textAlign: "center", position: "relative" }}
                            onClick={() => {
                              if (a?.clickHandle) {

                                a?.clickHandle(
                                  a?.previewUrl,
                                  a?.id,
                                  a?.image,
                                  a?.title,
                                  a?.artist,
                                  a?.externalUrl
                                );
                              }
                            }}
                          >
                            <PlanToast
                              available={["pro", "unlimited"]}
                              titleHeading="Popularity"
                            >
                              {a?.popularity}
                            </PlanToast>
                          </td>
                          {/* : null} */}
                          {HideDates ? null : (
                            <td
                            // onClick={() => {
                            //   console.log("clickhand");
                            //   a?.clickHandle(
                            //     a?.previewUrl,
                            //     a?.id,
                            //     a?.image,
                            //     a?.title,
                            //     a?.artist,
                            //     a?.externalUrl
                            //   );
                            // }}
                            >
                              <table className="startEndDate">
                                <tbody>
                                  <tr>
                                    <td>
                                      <p
                                      >
                                        Start date:
                                      </p>
                                    </td>
                                    <td>
                                      <h3 className="startEndTime">
                                        {a?.startTime}
                                      </h3>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p>End date:</p>
                                    </td>
                                    <td>
                                      <h3 className="startEndTime">
                                        {a?.endTime ? a?.endTime : "-"}
                                      </h3>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          )}
                          <td
                            onClick={() => {
                              // a?.clickHandle(
                              //     a?.previewUrl,
                              //     a?.id,
                              //     a?.image,
                              //     a?.title,
                              //     a?.artist,
                              //     a?.externalUrl
                              // );
                            }}
                          >
                            <a
                              className="playSpot"
                              target="blank"
                              href={"https://open.spotify.com/playlist/" + SearchId}
                            >
                              <img
                                alt="spotifylogo"
                                src={'./../static/images/spotifyLogo.png'} />
                              <p style={{ cursor: "pointer" }}>Play on Spotify</p>
                            </a>
                          </td>
                          <td className="actionsColumn">
                            {!hideMenu && menu ? (
                              <svg
                                cursor={"pointer"}
                                onClick={() => menu(a)}
                                stroke="currentColor"
                                fill="none"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                height="26px"
                                width="26px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 5H14V7H2V5Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M2 9H14V11H2V9Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M10 13H2V15H10V13Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M16 9H18V13H22V15H18V19H16V15H12V13H16V9Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            ) : a?.Edit ? (
                              <>{a?.Edit({ ...a, latestindex: i }) || null}</>
                            ) : (
                              <></>
                            )}
                            {hideMenu ? (
                              <img
                                alt="plusIcon"

                                src={"./static/icons_plus.svg"}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleAddSong(a)}
                              />
                            ) : null}
                          </td>
                        </tr>
                        // </DraggableRow>
                      );
                    })
                    : null}
                </tbody>
              </table>
            ) : (
              <>
                <div className="loadingDivPage">
                  <div className="loadingDiv">
                    <p className="refreshText playlistRefrestText">
                      Loading playlist
                    </p>
                    <div className="loadingCircle playlistloadingCircle"></div>
                  </div>
                </div>
              </>
            )}
          </>
        }
      </div>
    );
  };

  const MobTable = () => {
    return (
      <div className="mobTablePlaylist">
        <div className="innerMobTablePlaylist">
          {state && state?.map((a, i) => {
            return (
              <div className="rowMob">
                {playlistState?.previewSong?.id === a?.id ? (
                  <div style={{ padding: "6px 0px 0px 0px " }}>
                    <EqualizerIcon
                      style={{
                        marginLeft: "15px",
                      }}
                      className="icons45"
                    />
                  </div>
                ) : (
                  <div
                    className="playButtonPlayList"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <PlayArrowIcon
                      style={{
                        color: "white",
                        // marginLeft: "10px",
                      }}
                      className="icons1"
                    />
                    <span
                      className="icons2"
                      style={{
                        // marginLeft: "-10px",
                        color: "white",
                      }}
                    >
                      {a?.rowNumber?.indexes}
                    </span>
                  </div>
                )}

                <div className="songDetail">
                  <div>
                    {typeof a?.image === "string" ? (
                      <div className="imageName">
                        <span className="imgSpan">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              a?.clickHandle(
                                a?.previewUrl,
                                a?.id,
                                a?.image,
                                a?.title,
                                a?.artist,
                                a?.externalUrl
                              );
                            }}
                          >
                            <img
                              className="table-list-image"
                              alt="thumbnail"
                              src={a?.image}
                              width="40"
                              height="40"
                            />
                          </span>
                        </span>
                        <div className="namePlaylist">
                          <>
                            <p
                              id="hancker"
                              onClick={() => {
                                a?.clickHandle(
                                  a?.previewUrl,
                                  a?.id,
                                  a?.image,
                                  a?.title,
                                  a?.artist,
                                  a?.externalUrl
                                );
                              }}
                            >
                              {a?.title}
                            </p>
                            <p
                              id="hancker"
                              onClick={() => {
                                a?.clickHandle(
                                  a?.previewUrl,
                                  a?.id,
                                  a?.image,
                                  a?.title,
                                  a?.artist,
                                  a?.externalUrl
                                );
                              }}
                            >
                              {" "}
                              {a?.artist}
                            </p>
                          </>
                          {/* <h3>Cold Heart - PNAU Remix</h3>
                    <p>My Playlist</p> */}
                        </div>
                      </div>
                    ) : (
                      <div className="imageName">
                        <span className="imgSpan">{a?.image}</span>
                        <div className="namePlaylist">{a?.name}</div>
                      </div>
                    )}
                  </div>
                  {HideDates ? null : (
                    <div className="songTimeDetail">
                      <table className="startEndDate">
                        <tbody>
                          <tr>
                            <td>
                              <p>Start date:</p>
                            </td>
                            <td>
                              <h3 className="startEndTime">
                                <Popover
                                  content={
                                    <p className="hanker2">
                                      {moment(a?.added_at).format("llll")}
                                    </p>
                                  }
                                  placement="left"
                                  title="Start Time"
                                >
                                  <p
                                    id="hancker1"
                                    onClick={() => {
                                      a?.clickHandle(
                                        a?.previewUrl,
                                        a?.id,
                                        a?.image,
                                        a?.title,
                                        a?.artist,
                                        a?.externalUrl
                                      );
                                    }}
                                  >
                                    {" "}
                                    {moment(a?.added_at).format("DD/MM/YYYY")}
                                  </p>
                                </Popover>
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>End date:</p>
                            </td>
                            <td>
                              <h3 className="startEndTime">{a?.endTime}</h3>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <a
                        className="playSpot"
                        target="blank"
                        href={"https://open.spotify.com/playlist/" + SearchId}
                      // href={a?.externalUrl}
                      >
                        <img alt="Play on Spotify"
                          src={'./../static/images/spotifyLogo.png'} />
                        <p style={{ cursor: "pointer" }}>Play on Spotify</p>
                      </a>
                    </div>
                  )}
                  {!hideMenu && menu ? (
                    <svg
                      cursor={"pointer"}
                      onClick={() => menu(a)}
                      stroke="currentColor"
                      fill="none"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="26px"
                      width="26px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 5H14V7H2V5Z" fill="currentColor"></path>
                      <path d="M2 9H14V11H2V9Z" fill="currentColor"></path>
                      <path d="M10 13H2V15H10V13Z" fill="currentColor"></path>
                      <path
                        d="M16 9H18V13H22V15H18V19H16V15H12V13H16V9Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : (
                    <>{a?.Edit({ ...a, latestindex: i })}</>
                  )}
                  {hideMenu ? (
                    <img
                      alt="plusicon"
                      src={"./static/icons_plus.svg"}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAddSong(a)}
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <SongsTableStyle>
      <PcTable />
      <MobTable />
    </SongsTableStyle>
  );
}

export default SongsTable;