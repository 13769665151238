import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import moment from "moment";
import { AddToPlayListModalStyle } from "../AddToPlayListModal/AddToPlayListModal.style";
import { copyToClipboard } from "../../utils";
import { useLoginContext } from "../../context";
import "./ShareModal.css";
import axios from "axios";
import { BASE_URL } from "../../constants/api-urls";
type InstantShareModalProp = {
  isModalVisible?: any;
  setIsModalVisible?: any;
  editType?: any;
  currentPlaylistData?: any;
  titleType?: any;
};
function InstantShareModal({
  isModalVisible,
  setIsModalVisible,
  editType,
  currentPlaylistData,
  titleType
}: InstantShareModalProp) {
  const [notes, setNotes] = useState("");
  const [copied, setCopied] = useState(false);
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();
  const [originalDate, setOriginalDate] = useState("");
  const [ViewDetailData, setViewDetailData] = useState({
    song_uri: "",
    selected_playlist_id: "",
    id: "",
    position: "",
    started_time: "",
    endTime: "",
  });
  useEffect(() => {
    if (isModalVisible) {
      let instantShareDetail = JSON.parse(
        localStorage.getItem("instantShareDetail")
      );
      setViewDetailData({
        ...instantShareDetail,
      });
    }
  }, [isModalVisible]);
  const handleOk = () => {
    localStorage.removeItem("instantShareDetail");
    setIsModalVisible(false);
    if (setViewDetailData) {
      setViewDetailData({
        song_uri: "",
        selected_playlist_id: "",
        id: "",
        position: "",
        started_time: "",
        endTime: "",
      });
    }
  };
  const handleCancel = (e: any) => {
    localStorage.removeItem("instantShareDetail");
    e.stopPropagation();
    setIsModalVisible(false);
    if (setViewDetailData) {
      setViewDetailData({
        song_uri: "",
        selected_playlist_id: "",
        id: "",
        position: "",
        started_time: "",
        endTime: "",
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    const url = new URL(window.location);
    const originalDate = url.searchParams.get("originalDate");
    // setOriginalDate(
    //   moment(ViewDetailData?.started_time).format("DD-MM-YY, hh:mm A")
    // );
    console.log(ViewDetailData,'ViewDetailDataViewDetailData')
    setOriginalDate(ViewDetailData?.started_time)
  }, [ViewDetailData]);
  const [url, setUrl] = useState('')
  useEffect(() => {
    if (isModalVisible) {

      if (ViewDetailData) {

        let obj = {
          uri: ViewDetailData?.song_uri || currentPlaylistData?.uri,
          playlistId:
            ViewDetailData?.selected_playlist_id || currentPlaylistData?.id,
          id: ViewDetailData?.id,
          ref: loginState.refreshToken,
          position: ViewDetailData?.position,
          originalDate: originalDate,
          endTime: moment(ViewDetailData?.endTime).format("DD-MM-YY, hh:mm A"),
        };
        axios(`${BASE_URL}/createShareLinkToken/`, {
          data: obj,
          method: "post",
        }).then((res) => {
          console.log(window?.location?.origin + "/track-detail?token=" + res.data.data.token)
          setUrl(window?.location?.origin + "/track-detail?token=" + res.data.data.token);
        });
      }
    }
  }, [ViewDetailData, isModalVisible])
  const getShareURL = () => {
    // setShareUrlLoader(true)
    setCopied(true);
    // setShareUrlLoader(false)
    copyToClipboard(url);
    setTimeout(() => {
      setCopied(false);
    }, 1000);

  };

  return (
    <AddToPlayListModalStyle
      visible={isModalVisible}
      onOk={handleOk}
      footer={null}
      onCancel={handleCancel}
      className={`${editType}`}
    >
      <>
        <div className="viewDetailInner instantShareInner">
          <h1
            className="heading"
            onClick={() => {
              console.log(ViewDetailData);
            }}
          >
            Song {titleType ? titleType : ' added'}
          </h1>
          <div className="SuccessSubsImageDiv">
            <img
              alt="success-img"
              className="SuccessSubsImage"
              src={"./static/images/undraw_completing_re_i7ap.png"}
            />
          </div>
          <h3>Song has been successfully {titleType ? titleType : ' added'}!</h3>
          <p>Do you want to share song?</p>

          <div className="note songURlCopy">
            <Popover
              content={
                <span className="popSpan">
                  {url}
                </span>
              }
              placement="bottomLeft"
            >
              <span
                onClick={() => {
                  console.log(loginState, "loginStateloginState");
                }}
              >
                {url}
                {/* {window?.location?.origin +
                "/track-detail?uri=" +
                (ViewDetailData?.song_uri || currentPlaylistData?.uri) +
                "&playlistId=" +
                (ViewDetailData?.selected_playlist_id ||
                  currentPlaylistData?.id) +
                "&id=" +
                ViewDetailData?.id +
                "&ref=" +
                loginState.refreshToken +
                "&originalDate=" +
                originalDate +
                "&endTime" +
                moment(ViewDetailData.endTime).format("DD-MM-YY, hh:mm A")} */}
              </span>
            </Popover>
            <Popover
              overlayClassName="copyPop"
              content={<p>{copied ? "Copied" : "Copy"}</p>}
              placement="topLeft"
            >
              <button
                className="copyUrlSong"
                // onClick={() => {
                //   // console.log(ViewDetailData, 'ViewDetailData')
                //   setCopied(true);
                //   copyToClipboard(
                //     window?.location?.origin +
                //       "/track-detail?uri=" +
                //       (ViewDetailData?.song_uri || currentPlaylistData?.uri) +
                //       "&playlistId=" +
                //       (ViewDetailData?.selected_playlist_id ||
                //         currentPlaylistData?.id) +
                //       "&id=" +
                //       ViewDetailData?.id +
                //       "&ref=" +
                //       loginState.refreshToken +
                //       "&position=" +
                //       ViewDetailData?.position +
                //       "&originalDate=" +
                //       originalDate +
                //       "&endTime" +
                //       moment(ViewDetailData.endTime).format("DD-MM-YY, hh:mm A")
                //   );
                //   setTimeout(() => {
                //     setCopied(false);
                //   }, 1000);
                // }}
                onClick={getShareURL}
              >
                Copy
              </button>
            </Popover>
          </div>
        </div>
      </>
    </AddToPlayListModalStyle>
  );
}

export default InstantShareModal;
