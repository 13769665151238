import { useEffect, useState } from "react";
import { PlayListNew } from "../../assets";
import { usePlaylistContext } from "../../context/PlaylistContext";
import PlanToast from "../PlanToast/PlanToast";
import { PlayListBannerStyle } from "./PlayFututreListBanner.style";
import axios from "axios";
import { getItemFromStorage } from "../../utils";
import { SPOTIFY_TOKEN } from "../../constants/storage";
import { BASE_URL } from "../../constants/api-urls";

interface PlayListBannerProps {
  title?: string;
  totalSongs?: string;
  desc?: string;
  image?: string;
  openNotification?: any;
  setAddToPlaylistModal?: any;
  pageName?:any;
}

const PlayFutureListBanner = (props: PlayListBannerProps) => {
  const { title, totalSongs, desc, setAddToPlaylistModal } = props;
  const { state: playlistState } =
    usePlaylistContext();
  const [theme, setTheme] = useState("dark");
  const [previewImage, setPreviewImage] = useState(null);
  const [spotifyId, setSpotifyId] = useState('')

  useEffect(() => {
    setTheme(playlistState?.isWholeTheme === false ? "dark" : "light");
  }, [playlistState]);

  const getImage = (id) => {
    // axios.get(`${BASE_URL}/images/${id}/${props.pageName}`)
    //   .then((res) => {
    //     // console.log(res.data, 'imageimageimageimage')
    //     // const blob = new Blob([res.data], { type: 'image/jpeg' }); // Adjust type based on your image format
    //     // const imageUrl = URL.createObjectURL(blob);
    //     // console.log(imageUrl,'imageimageimageimage')
    //     setPreviewImage(res.data.url);
    //   }).catch((err) => {
    //     console.log(err, 'imageimageimageimage')
    //   })
  }
  const checkImageAndSetPreview = async (previewImageUrl) => {
    const fallbackImage = './static/images/playListPic.png';
  
    try {
      const response = await fetch(previewImageUrl, { method: 'HEAD' });
      if (response.ok) {
        setPreviewImage(previewImageUrl); // Image exists
      } else {
        setPreviewImage(fallbackImage); // Image does not exist, use fallback
      }
    } catch (error) {
      setPreviewImage(fallbackImage); // Fallback in case of an error
      console.error('Error checking image:', error);
    }
  };
  
  
  useEffect(() => {
    const token = getItemFromStorage(SPOTIFY_TOKEN);

    axios(`https://api.spotify.com/v1/me`, {
      method: "GET",
      headers: { Authorization: "Bearer " + token }
    })
      .then(resp => {
        setSpotifyId(resp?.data?.id)
        getImage(resp?.data?.id)
        if(resp?.data?.id && props.pageName){
          setPreviewImage(checkImageAndSetPreview(`${BASE_URL}/images/${resp?.data?.id}/${props.pageName}`))
        }
      })
      .catch(async error => {

      });
  }, [])

  const fileOnChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
      const formData = new FormData()
      formData.append('image', file)
      const config = formData;

      axios.post(`${BASE_URL}/upload/image/${spotifyId}/${props.pageName}`, config)
        .then((res) => {
          getImage(spotifyId)
        }).catch(() => {
          console.log('not uploaded')
        })

    }
  };

  return (
    <PlayListBannerStyle>
      <div className="container">
        <div className="text-container">
          <div className="platform">
          <img alt="spotify" src={"./../static/Spotify_Logo_RGB_Green.png"} />
          </div>
          <div className="row1">
            {theme === "dark" ? (
              <PlayListNew color={"white"} />
            ) : (
              <PlayListNew color={"#1D1D1D"} />
            )}
            <p className="p1">Playlist</p>
          </div>
          <div className="row-space" style={{}}>
            <div>
              <h1 className="title">{title}</h1>
              <p className="desc">{desc}</p>
            </div>

          </div>
          <div className="p2">
            <p>Spotify</p>
            <span className="platForm">-</span>
            <p className="platForm">{totalSongs}</p>
          </div>

          <div className="songPlayListButtons">
            <button className="addSong" onClick={() => {
              setAddToPlaylistModal(true)
            }}>
              <PlanToast available={['free', 'pro', 'unlimited']} titleHeading={'Add song'}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1955_58822)">
                    <path
                      d="M8.5801 6.46961H13.6001V8.52961H8.5801H8.2801V8.82961V13.8496H6.2201V8.82961V8.52961H5.9201H0.900098V6.46961H5.9201H6.2201V6.16961V1.14961H8.2801V6.16961V6.46961H8.5801Z"
                      fill="white"
                      stroke="#007AFF"
                      stroke-width="0.6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1955_58822">
                      <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0.25 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Add song
              </PlanToast>
            </button>

          </div>
        </div>
        <div className="playListPic">
          {/* <div className="imgDiv"> */}
          <img
            className="image imagecontainerdirection"
            src={previewImage || './static/images/playListPic.png'}
            alt="playlist thumbnail"
          />
          <label className="uploadImage">
            <div className="innerUploadImage">
              <input type="file" onChange={fileOnChange} />
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"></path></svg>
              <p>Add Photo</p>
            </div>
          </label>
          {/* </div> */}
          <div className="upcoming">Upcoming Releases</div>
        </div>
      </div>
    </PlayListBannerStyle>
  );
};

export default PlayFutureListBanner;
