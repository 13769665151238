import React, { useEffect, useState } from "react";
import ShareModal from "../../components/ShareModal/ShareModal";
import { ShareDetailPageStyle } from "./ShareDetailPageStyle.style";
import { BASE_URL } from "../../constants/api-urls";
import axios from "axios";
import { useLoginContext } from "../../context";
import { getRefreshedToken } from "../../utils";
import { SPOTIFY_TOKEN } from "../../constants/storage";

function ShareDetailPage() {
  const [shareSongModal, setShareSongModal] = useState(true);
  const [ViewDetailData, setViewDetailData] = useState({});
  const [currentPlaylistData, setCurrentPlaylistData] = useState<any>({});
  const [PlaylistRefreshRecord, setPlaylistRefreshRecord] = useState([]);
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    setLoader(true)
    const fetchData = async () => {
      try {

        axios(`${BASE_URL}/decodeShareLinkToken/`, {
          data: {
            // @ts-ignore
            token: new URL(window.location).searchParams.get("token")
          },
          method: "post",
        }).then((link) => {
          console.log(link,'linklink')
          const url = link.data.data;
          const uri = url.uri
          const id = url.id
          const playlistId = url.playlistId
          const refreshToken = url.ref
          const position = url.position
          const originalDate = url.originalDate

          getRefreshedToken(refreshToken).then(async (response) => {
            console.log(response, "dasdasdadasdas");
            let token = response?.data?.access_token;
            // localStorage.setItem(SPOTIFY_TOKEN, response?.data?.access_token);
            // loginDispatch({
            //   type: "ADD_TOKEN",
            //   payload: response?.data?.access_token,
            // });
            if (token) {
              if (uri && playlistId) {
                const config = {
                  song_uri: uri,
                  selected_playlist_id: playlistId,
                };

                // Post request to get song details from your backend
                const response = await axios.post(
                  `${BASE_URL}/getSongDetails`,
                  config
                );
                console.log(response, "response");
                let responseSong = null
                // Get request to Spotify API to get additional song details
                await axios.get(
                  `https://api.spotify.com/v1/tracks/${id}`,
                  {
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                ).then((resSong) => {
                  console.log(resSong,'asdasdasdasdasdasdas')
                  responseSong = resSong
                }).catch(() => {

                })

                if (playlistId) {
                  axios(`https://api.spotify.com/v1/playlists/${playlistId}`, {
                    method: "GET",
                    headers: { Authorization: "Bearer " + token },
                  })
                    .then((responses) => {
                      let currentPlaylistContent = responses.data;
                      setCurrentPlaylistData(currentPlaylistContent);

                      // Check if both responses are valid
                      if (response?.data) {

                        setPlaylistRefreshRecord(
                          response.data?.data?.PlaylistRefreshRecords
                        );

                      }
                    })
                    .catch(async (error) => {

                      setLoader(false)

                    });
                }
                setLoader(false)
                // Set the view detail data with the combined response
                // console.log({
                //   ...ViewDetailData,
                //   notes: response.data?.data?.songData?.notes || "",
                //   track: {
                //     name: responseSong?.data?.name || response.data?.data?.songData?.song_name  || "",
                //     artists: [
                //       {
                //         name: responseSong?.data?.artists[0]?.name || response.data?.data?.songData?.artist_name || "",
                //       },
                //     ],
                //     album: {
                //       images: [
                //         {
                //           url:
                //             responseSong?.data?.album?.images[0]?.url || 
                //             "",
                //         },
                //       ],
                //     },
                //     uri: responseSong?.data?.songData?.uri || response.data?.data?.songData?.song_name || uri ||  "",
                //   },
                //   popularity: responseSong?.data?.popularity,
                //   position: position,
                //   added_at: originalDate || 
                //     response.data?.data?.songData?.started_time ||
                //     // currentPlaylistContent?.tracks?.items[0].added_at ||
                //     "-",
                //   endTime:
                //     response.data?.data?.songData?.end_time === "null"
                //       ? "-"
                //       : response.data?.data?.songData?.end_time || "",
                //   song_uri: responseSong?.data?.songData?.uri || uri ||  "",
                //   selected_playlist_id:
                //     response.data?.data?.songData?.selected_playlist_id ||
                //     ""
                // },'sadasdasdadas')
  console.log(originalDate,'originalDateoriginalDateoriginalDate')
                setViewDetailData({
                  ...ViewDetailData,
                  notes: response.data?.data?.songData?.notes || "",
                  track: {
                    name: responseSong?.data?.name || response.data?.data?.songData?.song_name  || "",
                    artists: [
                      {
                        name: responseSong?.data?.artists[0]?.name || response.data?.data?.songData?.artist_name || "",
                      },
                    ],
                    album: {
                      images: [
                        {
                          url:
                            responseSong?.data?.album?.images[0]?.url || 
                            "",
                        },
                      ],
                    },
                    uri: responseSong?.data?.songData?.uri || response.data?.data?.songData?.song_name || uri ||  "",
                  },
                  popularity: responseSong?.data?.popularity,
                  position: position,
                  added_at: originalDate || 
                    response.data?.data?.songData?.started_time 
                    // orgDate
                    // ||
                    // response?.data?.data?.PlaylistRefreshRecords?.[0]?.created_at
                    // currentPlaylistContent?.tracks?.items[0].added_at ||
                    ,
                  endTime:
                    response.data?.data?.songData?.end_time === "null"
                      ? "-"
                      : response.data?.data?.songData?.end_time || "",
                  song_uri: responseSong?.data?.songData?.uri || "",
                  selected_playlist_id:
                    response.data?.data?.songData?.selected_playlist_id ||
                    "",
                  // notes: response.data?.data?.songData?.notes || "",
                  // track: {
                  //   name: response.data?.data?.songData.name || response.data?.data?.songData.song_name || "",
                  //   artists: [
                  //     {
                  //       name: response.data?.data?.songData?.artist_name || "",
                  //     },
                  //   ],
                  //   album: {
                  //     images: [
                  //       {
                  //         url:
                  //           response.data?.data?.album?.images[0]?.url ||
                  //           "",
                  //       },
                  //     ],
                  //   },
                  //   uri: response.data?.data?.songData?.uri || "",
                  // },
                  // popularity: response.data?.data?.popularity,
                  // position: position,
                  // added_at: originalDate ||
                  //   response.data?.data?.data?.songData?.started_time ||
                  //   "-",
                  // endTime:
                  //   response.data?.data?.data?.songData?.end_time === "null"
                  //     ? "-"
                  //     : response.data?.data?.data?.songData?.end_time || "",
                  // song_uri: response.data?.data?.songData?.uri || "",
                  // selected_playlist_id:
                  //   response.data?.data?.data?.songData?.selected_playlist_id ||
                  //   "",
                });


              }
            }
          });
        });

      } catch (error) {
        console.error("Error fetching song details:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log(ViewDetailData, 'ViewDetailDataViewDetailData')
  }, [ViewDetailData])
  return (
    <ShareDetailPageStyle>
      <ShareModal
        isModalVisible={shareSongModal}
        setIsModalVisible={() => {
          setShareSongModal(false);
          window.location.href = "/";
        }}
        editType={"viewDetail"}
        ViewDetailData={ViewDetailData}
        setViewDetailData={setViewDetailData}
        currentPlaylistData={currentPlaylistData}
        PlaylistRefreshRecord={PlaylistRefreshRecord}
        showCopyUrl={false}
        scheduleSong={false}
        detailLoader={loader}
      />
    </ShareDetailPageStyle>
  );
}

export default ShareDetailPage;
