import { Avatar, Button, Drawer, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import "./header.css";
import { Auth0Logout, clearSiteStorage, deleteItemFromStorage } from "../../utils";
import { SPOTIFY_CODE, SPOTIFY_TOKEN, SPOTIFY_USER_INFO } from "../../constants/storage";
import { useLoginContext } from "../../context";
interface HeaderProps {
  onOpen: (boolean) => void;
  onOpenSignUp: (boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ onOpen, onOpenSignUp }) => {
  const [open, setOpen] = useState(false);
  const { dispatch } = useLoginContext();
  const [activeNav, setActiveNav] = useState('Home')
  const [localUserData, setLocalUserData] = useState({});
  const [spotifyConnectedUser, setSpotifyConnectedUser] = useState({
    code: "",
    image: "",
    username: "",
  });
  useEffect(() => {
    const spotifyUser = JSON.parse(localStorage.getItem("spotifyUser"));
    setSpotifyConnectedUser(spotifyUser);
    const songplaceUser = JSON.parse(localStorage.getItem("userInfo"))
    setLocalUserData(songplaceUser)
  }, [])
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const activeNavFn = () => {
    let active = window.location.hash.split('#')[1]
    setActiveNav(active)
  }
  useEffect(() => {
    activeNavFn()
  }, [])

  const handleLogout = async () => {
    deleteItemFromStorage(SPOTIFY_TOKEN);
    deleteItemFromStorage(SPOTIFY_CODE);
    deleteItemFromStorage(SPOTIFY_USER_INFO);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("spotifyUser");
    clearSiteStorage();

    dispatch({
      type: "SET_LOGIN",
      payload: false,
    });
    Auth0Logout();
  };


  const imgstyle = {
    marginLeft: "0px",
    width: "235",
    height: "54"

  }
  const items = [
    {
      key: '1',
      label: (
        <div className="avDropDown">
          <p className="accName" onClick={() => {
            console.log(spotifyConnectedUser, 'spotifyConnectedUser')
          }}>{spotifyConnectedUser?.username?.toUpperCase()}</p>
          {/* @ts-ignore */}
          <p className="accEmail">{localUserData?.email}</p>
        </div>
      )
    },
    {
      key: '2',
      label: (
        <a rel="noopener noreferrer" href="/home">
          Dashboard
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <div onClick={handleLogout}>
          Logout
        </div>

      ),
    }
  ];
  return (
    <div className="row SideStyle-row">
      <img
        alt="songplace.png"
        className="SideStyleImage"
        src="./static/songplace.png"
        width="180px"
        style={imgstyle}

      />
      <div className="navbar">
        <a className={`navbar-li ${activeNav === 'Home' ? 'active' : ''}`} href="#Home" onClick={() => setActiveNav('Home')}>Home</a>
        <a className={`navbar-li ${activeNav === 'About' ? 'active' : ''}`} href="#About" onClick={() => setActiveNav('About')}>About</a>
        <a className={`navbar-li ${activeNav === 'Features' ? 'active' : ''}`} href="#Features" onClick={() => setActiveNav('Features')}>Features</a>
        <a className={`navbar-li ${activeNav === 'Pricing' ? 'active' : ''}`} href="#Pricing" onClick={() => setActiveNav('Pricing')}>Pricing</a>
        <a className={`navbar-li ${activeNav === 'Contact' ? 'active' : ''}`} href="#Contact" onClick={() => setActiveNav('Contact')}>Contact</a>
      </div>
      <div className="row4">
        {localUserData ?
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
            overlayClassName="dropDownDashboardOverlay"
          >
            <Button className="btn2 dropDownButtonAv">
              {spotifyConnectedUser?.username[0]?.toUpperCase()}
            </Button>
          </Dropdown>

          :
          <>
            <button
              // onClick={handleFormSubmit}
              onClick={() => {
                window.open('https://submit.songplace.io/', '_blank');
              }}
              className="loginButton"
            >
              <span>
                Are you an artist?
              </span>
            </button>
            <button
              // onClick={handleFormSubmit}
              onClick={() => onOpen(true)}
              className="SideStyleButton"
            >
              Login
            </button>
          </>
        }
        <img alt="burgerMenu.svg" src="./static/burgerMenu.svg" onClick={showDrawer} width="30" height="30" />
      </div>
      <Drawer title="" placement="left" onClose={onClose} open={open}>
        <span className="drawer-li" style={{ cursor: "pointer" }}>Home</span>
        <span className="drawer-li" style={{ cursor: "pointer" }}>About</span>
        <span className="drawer-li" style={{ cursor: "pointer" }}>Features</span>
        <span className="drawer-li" style={{ cursor: "pointer" }}>Pricing</span>
        <span className="drawer-li" style={{ cursor: "pointer" }}>Contact</span>
        <span className="drawer-li" onClick={() => {
          window.open('https://submit.songplace.io/', '_blank');
        }} style={{ cursor: "pointer" }}>Are you an artist?</span>
        <div className="row4">
          <button
            onClick={(ev) => {
              onOpen(ev);
              onClose();
            }}
            // onClick={() => onOpen(true)}
            className="drawer-SideStyleButton"
          >
            Login/ Signup
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
